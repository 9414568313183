@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.copy {
    flex-shrink: 100;
}

.overlay {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 1.2rem;
    border-radius: 2rem;
    margin: 0 1.2rem;
    animation: fade-in $fast forwards;
    background: var(--colors-white);
    box-shadow: 0 2px 10px rgba(67, 57, 52, 4%), 0 2px 40px rgba(84, 67, 57, 7%);
    color: var(--colors-blue);
    text-align: center;
}

.options {
    display: flex;
    max-width: 3 * 10.1rem;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-top: 1.2rem;
    gap: 1.2rem 0;
}

.options__item {
    position: relative;
    display: flex;
    width: 10.1rem;
    flex-direction: column;
    align-items: center;
    padding: 0;
    border: 0;
    appearance: none;
    background: none;
    color: var(--colors-blue);
    font-weight: 100;

    img {
        max-width: 7.2rem;
        margin-bottom: 1.1rem;
    }

    &::after {
        @include spread;

        position: absolute;
        display: block;
        background-image: url('./images/checkmark.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 3rem 3rem;
        content: '';
        opacity: 0;
        transition: opacity $fast;
    }
}

.options__item--selected {
    &::after {
        opacity: 1;
    }
}

.actions {
    display: flex;
    padding-top: 2.4rem;
    gap: 1.2rem;
}

.closeButton {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    width: 3rem;
    height: 3rem;
    border: 0;
    background: transparent;
    color: var(--colors-blue);
}

.loading {
    @include spread;

    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--colors-white);
}

.iframeContainer {
    position: absolute;
    margin-bottom: 1.8rem;
    visibility: hidden;

    &--loaded {
        position: relative;
        visibility: visible;
    }

    iframe {
        display: block;
    }
}

.overlayBackground {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 4rem);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlayImage {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 30% top;
}

.mapIcon {
    overflow: hidden;
    width: 4.5rem;
    height: 5rem;
    margin-bottom: 1.2rem;
    background: url('./images/map-icon.svg') no-repeat;
    filter: drop-shadow(-1.3158px 0.8772px 0.8772px rgba(137, 136, 123, 20%));
}

.heading {
    text-transform: uppercase;
}

.loadingIcon {
    width: 23rem;
    margin-bottom: 3.2rem;
}

.loadingHeading {
    max-width: 20rem;
}

// Destini specific
@media (min-width: 770px) {
    .iframeContainer {
        margin-bottom: 0;
    }
}

@include medium {
    .overlay {
        padding: 3.6rem;
    }

    .options {
        max-width: 3 * 12.2rem;
        padding-top: 3.6rem;
    }

    .options__item {
        width: 12.2rem;

        &::after {
            background-size: 4rem 4rem;
        }

        img {
            max-width: 10.9rem;
        }
    }

    .actions {
        padding-top: 3.6rem;
    }

    .overlayImage {
        object-position: center;
    }
}

@include large {
    .loadingHeading {
        max-width: 58.9rem;
    }

    .overlayBackground {
        height: calc(100vh - 6rem);
    }

    .overlay {
        padding: 4.8rem;
    }

    .mapIcon {
        width: 5.2rem;
        height: 5.7rem;
    }

    .banner {
        align-items: center;
        padding: 1.4rem var(--grid-padding-side);
    }

    .options {
        max-width: 100%;
        padding-top: 3.6rem;
    }

    .actions {
        padding-top: 4.8rem;
    }

    .closeButton {
        position: static;
    }
}
